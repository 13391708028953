import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap/js/src/util';
import SmoothScroll from 'smooth-scroll';

import WebFont from "webfontloader";
WebFont.load({
  google: {
    families: ["Roboto:300,400,500"]
  },
  custom: {
    families: ['Font Awesome\ 5 Icons:400,900', 'Font Awesome\ 5 Brands:400'],
    urls: ['//use.fontawesome.com/releases/v5.0.8/css/all.css']
  }
});

import './assets/scss/main.scss'

$(document).ready(() => {
  let scroll = new SmoothScroll('#navbarNavAltMarkup a[href*="#"], #menu-logo')
  
  $(window).scroll(function (event) {
    const scroll = $(window).scrollTop();
    if (scroll > 60) {
      $("#nav-menu").addClass('menu-bg')
    } else {
      $("#nav-menu").removeClass('menu-bg')
    }
  });
  
  function log(obj) {
    console.log(obj)
  }
})
